@import '~@angular/material/theming';

@include mat-core();

$pallete-primary: (
    900: var(--primary-color),
    contrast: (
        900: var(--primary-contrast),
    ),
);
$pallete-accent: (
    900: var(--accent-color),
    contrast: (
        900: var(--accent-contrast),
    ),
);

$app-primary: mat-palette($pallete-primary, 900, 900, 900);
$app-accent: mat-palette($pallete-accent, 900, 900, 900);
$app-warn: mat-palette($mat-red);

$primary: var(--primary-color);
$accent: var(--accent-color);
$warn: mat-color($app-warn);

$text-color: var(--primary-contrast);
$accent-text-color: var(--accent-contrast);

$primary-darken: var(--primary-color-darken);