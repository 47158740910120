@import 'variables';

$sb-cibo-app-web-light-theme: mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

$sb-cibo-app-web-dark-theme: mat-dark-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

@mixin el-border($color) {
    border: 1px solid $color;
}


@mixin theme-params($background, $color) 
{
  * {
    scrollbar-color: $primary $background;
    scrollbar-width: auto;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $background;
  }
  
  ::-webkit-scrollbar {
    width: 12px;
    background-color: $background;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primary;
    background-clip: content-box;
    border: .25rem solid transparent;
  }
  
  .draggable-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: $color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: $background;
    font-size: 14px;
    flex-wrap: wrap;
    overflow: auto;
  }

  .draggable-list {
    border: solid 1px #ccc;
    background: $background;
    border-radius: 4px;
    overflow: auto;
    display: block;
  }

  .ngx-charts {
    text {
      fill: $color !important;
    }
    .reference-area {
      fill-opacity: 0 !important;
    }
    .refline-label {
      font-size: 12px !important;
    }
  }
  .text-border {
    @include el-border($color);
  }
}

@include angular-material-theme($sb-cibo-app-web-light-theme);

.light-theme {
  --primary-color: #3f51b5;
  --primary-color-darken: #002984;
  --primary-contrast: #ffffff;
  --accent-color: #fb8c00;
  --accent-contrast: #000000;
  @include theme-params(white, black);
  .mat-drawer-container {
    background-color: #f0f0f0;
  }
}

.dark-theme {
  --primary-color: #5472d3;
  --primary-color-darken: #0d47a1;
  --primary-contrast: #ffffff;
  --accent-color: #fb8c00;
  --accent-contrast: #000000;
  @include theme-params(#303030, white);
  @include angular-material-color($sb-cibo-app-web-dark-theme);
}