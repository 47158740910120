@import 'material-theme.scss';
@import 'utils.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

table {
    width: 100%;
}

.mat-table {
    $table-padding: 1em;
    $icon-width: 45px;
    tr {
        th:not(:first-of-type) {
            padding-left: $table-padding;
        }
        td:not(:first-of-type) {
            padding-left: $table-padding;
        }
    }
    .options-col {
        width: calc(#{$icon-width} * 2);
    }
    .options-col-3 {
        width: calc(#{$icon-width} * 3);
    }
    .date-col {
        width: 200px;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .detail-row {
        height: 0;
    }
    .element-row {
        td {
            border-bottom-width: 0;
        }
        &:hover {
            background-color: $primary;
            cursor: pointer;
            td {
                color: $text-color;
            }
        }
    }
    .element-detail {
        overflow: hidden;
        display: flex;
    }
    .expanded-row {
        background-color: $primary;
        td {
            color: $text-color !important;
        }
    }
    td.mat-table-sticky {
        z-index: 10 !important;
    }
    .small-col {
        width: 5em;
    }
}


.mat-dialog-title {
    background-color: $primary;
    color: $text-color;
    padding: 0.5em;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

app-root {
    .nav-sidenav {
        .mat-list-item-content {
            overflow: hidden;
            word-break: break-word;
        }
    }
}


.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.nav-sidenav {
    min-width: 200px;
    width: 200px;
    .mat-list-base {
        padding-top: 0;
    }
    a {
        &:hover {
            color: $text-color !important;
            background-color: $primary-darken !important;
        }
        &.activated {
            color: $text-color !important;
            background-color: $primary-darken !important;
        }
        .mat-icon {
            margin-right: 10px;
        }
    }
    .mat-divider {
        background-color: $primary;
    }
}

.mat-dialog-container {
    &#error-msg {
        background-color: $warn;
        color: white;
    }
}

.mat-form-field {
    width: 100%;
}

.green-font {
    color: green !important;
}

.orange-font {
    color: darkorange;
}

.red-font {
    color: red;
}

.mat-icon {
    &.big-icon {
        $icon-size: 100px;
        width: $icon-size;
        height: $icon-size;
        font-size: $icon-size;
    }
    &.small-icon {
        $icon-size: 21px;
        width: $icon-size;
        height: $icon-size;
        font-size: $icon-size;
    }
    &.dashboard-icon {
        $icon-size: 50px;
        width: $icon-size;
        height: $icon-size;
        font-size: $icon-size;
    }
}

.primary-bg {
    background-color: $primary !important;
    color: $text-color !important;
}

.full-screen-panel {
    max-width: 100vw!important;
}

.table-autocomplete {
    .mat-option {
        font-size: 0.9em;
        line-height: 3em;
        height: 3em;
    }
}

.green-border {
    @include el-border(limegreen);
}

.orange-border {
    @include el-border(darkorange);
}

.red-border {
    @include el-border(red);
}

.blue-border {
    @include el-border(rgb(2, 2, 172));
}

.dashboard {
    mat-expansion-panel-header {
        font-size: 12px;
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }
    mat-card {
        cursor: pointer;
        font-size: 14px;
        padding: 10px;
    }
}

.warn {
    background-color: $warn;
    color: white;
}

.config-tooltip {
    font-size: 0.75rem;
    white-space: pre-line;
    max-width: 100% !important;
}

.highlight-row {
    background-color: $primary !important;
    td {
        color: $text-color !important;
    }
}

.highlight-div {
    background-color: $primary !important;
    color: $text-color !important;
}

.month-picker  {
    .mat-calendar-period-button {
        pointer-events: none;
    }
    .mat-calendar-arrow {
        display: none;
    }
}
