$spacer: 1rem;
$spacer-1: calc(#{$spacer} * 0.25);
$spacer-2: calc(#{$spacer} * 0.5);
$spacer-3: calc(#{$spacer} * 1);
$spacer-4: calc(#{$spacer} * 1.5);
$spacer-5: calc(#{$spacer} * 3);


// MARGIN
.m-0 {
    margin: 0 !important;
}

.ml-2 {
    margin-left: $spacer-2 !important;
}

.mr-2 {
    margin-right: $spacer-2 !important;
}

.my-2 {
    margin-top: $spacer-2 !important;
    margin-bottom: $spacer-2 !important;
}

.my-3 {
    margin-top: $spacer-3 !important;
    margin-bottom: $spacer-3 !important;
}

.mx-2 {
    margin-right: $spacer-2 !important;
    margin-left: $spacer-2 !important;
}

// PADDING
.p-0 {
    padding: 0 !important;
}

.p-2 {
    padding: $spacer-2 !important;
}

.py-2 {
    padding-top: $spacer-2 !important;
    padding-bottom: $spacer-2 !important;
}

.form-padding {
    padding-bottom: 1.34375em;
}

// WIDTH
.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.w-auto {
    width: auto !important;
}

// HEIGHT
.h-100 {
    height: 100% !important;
}

// OVERFLOW
.overflow-hidden {
    overflow: hidden !important;
}

.overflow-auto {
    overflow: auto !important;
}

// CURSOR
.cursor-move {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

// TEXT-ALIGN
.text-center {
    text-align: center !important;
}

// WHITE-SPACE
.ws-pre-wrap {
    white-space: pre-wrap;
}

// DISPLAY
.d-flex {
    display: flex !important;
}

// TEXT-INDENT
.indent-tab {
    text-indent: 26px !important;
}

// DISPLAY
.d-none {
    display: none !important;
}

// FLEX
.flex-grow {
    flex-grow: 1 !important;
}

// FLEX-GAP
.gap-5 {
    gap: 5px !important;
}

.gap-10 {
    gap: 10px !important;
}

.gap-25 {
    gap: 25px !important;
}

.gap-50 {
    gap: 50px !important;
}

// WORD-BREAK
.wb-break-word {
    word-break: break-word !important;
}